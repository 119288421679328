<template>
    <div class="webAboutUs">
        <div class="d-w-title flex-sbc">
            <div class="wt-title flex-wc" @click="clickGoBalck">
                <div class="balck">
                    <i class="el-icon-arrow-left"></i>
                </div>
                <div class="title">关于我们</div>
            </div>
            <div class="wt-logo">
                <img class="logo" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240509/e67d3671835aaedf99c9631d006ac7ca.png" alt="">
            </div>
        </div>
        <img class="au-head-wrap" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/9a48407ef4a51b77947ef6e88364df7c.png" alt="">
        <div class="au-about-wrap">
            <div class="aw-title">关于东有创新</div>
            <div class="aw-content">东有创新团队自2018年成立以来，致力为传统实体企业提供创新、先进的营销管理数字化解决方案，帮助企业在数字化转型升级上有更高效、可持续的落地工具和务实的解决方案。我们先后为近二十家制造业企业提供互联网技术解决方案，2020年获千万级天使轮融资。由于多年服务于制造业企业及终端经销商所带来的经验，我们深知小微企业在数字化转型上的痛点，以及如何应对这一问题。将多年来的经验，关于产品、小微企业业务特性等形成方法论和工具，为企业发展提供高效的工具及解决方案。</div>
            <div class="aw-box">
                <el-carousel height="320px" :interval="5000">
                    <el-carousel-item v-for="(item,index) in aboutList" :key="index">
                        <img class="b-image" :src="item" alt="">
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <div class="au-add-wrap">
            <div class="aw-title">加入我们</div>
            <div class="aw-tag">工作地址：深圳、广州、佛山、东莞</div>
            <div class="aw-tag">简历投递：David@ukoocloud.com</div>
        </div>
        <div class="au-foot-wrap">
            <div class="af-item">
                <div class="af-title">有库云销售顾问</div>
                <div class="af-tag">社招  |  大专及以上  |  工作经验不限</div>
            </div>
            <div class="af-item">
                <div class="af-title">职位描述</div>
                <div class="af-tag">1.通过外出拜访的形式，开拓客户；</div>
                <div class="af-tag">2.与客户建立客勤关系，了解客户的需求，结合需求给客户介绍公司的产品 ；</div>
                <div class="af-tag">3.制定销售计划，做好客户的服务和维护，最终达成业务合作；</div>
                <div class="af-tag">4.日常销售相关的数据收集和整理 ；</div>
                <div class="af-tag">5.收集客户的反馈意见，以及反馈给公司相关部门；</div>
            </div>
            <div class="af-item">
                <div class="af-title">职位要求</div>
                <div class="af-tag">3年以上to B（对企业）销售经验；</div>
                <div class="af-tag">能吃苦，有梦想，有思想，对自己有清楚的职业规划；</div>
                <div class="af-tag">语言交流能力出众，有亲和力；</div>
                <div class="af-tag">文字写作能力和逻辑思维能力较好，善于总结归纳；</div>
            </div>
        </div>
        <div class="au-tip-wrap">东有创新©粤TCP备2023127805号-1</div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            aboutList: [
                'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/d39d07831b849a89bd53486882c0dd5b.png',
                'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/90811230fdd690ae2ccf1a981ec039f5.png',
                'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240801/72e6e5969c7606d45180e49278d7c592.png',
            ]
        }
    },
    mounted () {

    },
    methods: {
        clickGoBalck(){
            this.$router.go(-1) 
        },
    },
}
</script>

<style lang='scss'>
.webAboutUs{
    .d-w-title{
        height: 50px;
        background: #fff;
        padding: 0 20px;
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        z-index: 9;
        .wt-title{
            .balck{
                .el-icon-arrow-left{
                    font-size: 16px;
                    color: #101010;
                    font-weight: bold;
                }
            }
            .title{
                font-weight: bold;
                font-size: 16px;
                color: #101010;
                margin-left: 10px;
                
            }
        }
        .wt-logo{
            .logo{
                width: 70px;
                height: 15px;
            }
        }
    }
    .au-head-wrap{
        width: 100%;
        height: 270px;
    }
    .au-about-wrap{
        padding: 50px 20px;
        background: #eff5fd;
        .aw-title{
            font-size: 20px;
            color: #101010;
            font-weight: bold;
            text-align: center;
            padding: 10px 30px 30px 30px;
        }
        .aw-content{
            font-size: 14px;
            color: #000;
            line-height: 24px;
            text-align: justify;
        }
        .aw-box{
            border-radius: 10px;
            margin-top: 50px;
            height: 320px;
            position: relative;
            overflow: hidden;
            .b-image{
                height: 100%;
                width: 100%;
                vertical-align: bottom;
            }
            .el-carousel__button{
                width: 8px;
                height: 8px;
                border-radius: 100%;
                background: #e5efff;
            }
            .el-carousel__indicator--horizontal{
                .el-carousel__button{
                    background: #96c0fd;
                }
            }
        }
    }
    .au-add-wrap{
        padding: 40px 20px;
        .aw-title{
            font-size: 20px;
            color: #101010;
            font-weight: bold;
            padding-bottom: 15px;
        }
        .aw-tag{
            font-size: 13px;
            color: #a7a7a7;
            padding: 5px 0;
        }
    }
    .au-foot-wrap{
        padding: 30px 20px 10px 20px;
        background: #eff5fd;
        margin: 0 20px;
        .af-item{
            padding-bottom: 15px;
            .af-title{
                font-size: 20px;
                color: #101010;
                font-weight: bold;
                padding-bottom: 5px;
            }
            .af-tag{
                font-size: 13px;
                color: #7b7b7b;
                padding: 5px 0;
                line-height: 20px;
            }
        }
    }
    .au-tip-wrap{
        font-size: 13px;
        color: #7b7b7b;
        text-align: center;
        padding: 30px 0;
    }
}
</style>
